import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faComment } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import classNames from 'classnames/bind';
import styles from './Contact.module.scss';

import phone_logo from '~/assets/image/logo/phone-logo.png';
import zalo_logo from '~/assets/image/logo/zalo-logo.png';
import messenger from '~/assets/image/logo/messenger.webp';

const cx = classNames.bind(styles);

const ContactButtons = () => {
  const phoneNumber = '0919196223'; // Điền số điện thoại của bạn ở đây
  const zaloLink = `https://zalo.me/${phoneNumber}`; // Tạo liên kết Zalo với số điện thoại
  const telLink = `tel:${phoneNumber}`;

  return (
    <>
      <div className={cx('contact-buttons')}>
        {/* phone */}
        <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
          <a href={telLink} className={cx('contact-container')}>
            <div className={cx('circle-hotline-button')}>
              <div className={cx('hotline-icon')}>
                <img src={phone_logo} alt="phone" />
              </div>
            </div>
          </a>
          <span hidden={true} className={cx('hotline-label')}>
            {phoneNumber}
          </span>
        </div>
        {/* Zalo button */}
        <div hidden={true}>
          <a href={zaloLink} target="_blank" rel="noopener noreferrer" className={cx('contact-container')}>
            <div className={cx('circle-zalo-button')}>
              <div className={cx('zalo-icon')}>
                <img src={zalo_logo} alt="zalo" />
              </div>
            </div>
          </a>
        </div>
      </div>

      {/* <div className={cx('contact-buttons-right')}>
        <div>
          <a className={cx('contact-container')}>
            <div className={cx('circle-messenger-button')}>
              <div className={cx('messenger-icon')}>
                <img src={messenger} alt="messenger" />
              </div>
            </div>
          </a>
        </div>
      </div> */}
    </>
  );
};

export default ContactButtons;
